import revive_payload_client_tYv7wGgHtz from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZdMoBmoETR from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QJHg67AEFQ from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_41v0nsPsc1 from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WugbK1bMeE from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_s7PexQa2h7 from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yG3JD3Uk5o from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FfAN1nlUNB from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_TW8Jhr0kp2 from "/app/node_modules/.pnpm/nuxt@3.12.3_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8A6FyAcbd6 from "/app/node_modules/.pnpm/@nuxt+icon@1.5.3_rollup@4.18.1_vite@5.3.3_vue@3.4.31/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_gvfHkMKtBQ from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.51.1_nuxt@3.12.3_rollup@4.18.1/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import i18n_x3zNa5vUA4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import errorCodeHandler_5jOIB6Wwtw from "/app/plugins/errorCodeHandler.ts";
import productFruits_f0XtXUbMc8 from "/app/plugins/productFruits.ts";
import stripe_6FZUdDHpIZ from "/app/plugins/stripe.ts";
export default [
  revive_payload_client_tYv7wGgHtz,
  unhead_ZdMoBmoETR,
  router_QJHg67AEFQ,
  payload_client_41v0nsPsc1,
  navigation_repaint_client_WugbK1bMeE,
  check_outdated_build_client_s7PexQa2h7,
  chunk_reload_client_yG3JD3Uk5o,
  plugin_vue3_FfAN1nlUNB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TW8Jhr0kp2,
  plugin_8A6FyAcbd6,
  plugin_gvfHkMKtBQ,
  i18n_x3zNa5vUA4,
  errorCodeHandler_5jOIB6Wwtw,
  productFruits_f0XtXUbMc8,
  stripe_6FZUdDHpIZ
]