import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { OrganizationTypeEnum } from '~/services/MSAPI/user/organization/types.js'
import type { NLogin } from '~/services/MSAPI/user/authentication/types'
import type { NGetSession } from '~/services/MSAPI/user/user/types'
import type { EPlanType } from '~/constants/organizationPlan'

// TODO: AVALIAR DADOS SENSÍVEIS NO LOCALSTORAGE

export interface IUserData {
  name?: string | null
  userId?: string | null
  email?: string | null
  language?: number | null
  createdDateTime?: string | null
  dateTimeFormat?: string | null
  dateFormat?: string | null
  timeZone?: string | null
}

export interface IOrganizationData {
  name?: string | null
  organizationBalanceConfigType?: number | null
  organizationBaseId?: string | null
  organizationStatus?: number | null
  organizationType?: number | null
  planName?: string | null
  planType?: EPlanType | null
}

const emptyUserData: IUserData = {
  name: null,
  userId: null,
}

export const useAuthStore = defineStore('auth', () => {
  const accessTokenKey = 'accessToken'
  const refreshTokenKey = 'refreshToken'
  const userDataKey = 'userData'
  const organizationDataKey = 'organizationDataKey'

  const accessToken = useStorage<string | null>(
    accessTokenKey,
    localStorage.getItem(accessTokenKey),
  )

  const refreshToken = useStorage<string | null>(
    refreshTokenKey,
    localStorage.getItem(refreshTokenKey),
  )

  const storedUserData = localStorage.getItem(userDataKey)

  const initialUserData: IUserData = storedUserData
    ? JSON.parse(storedUserData)
    : emptyUserData

  const initialOrganizationData: IOrganizationData = {
    name: null,
    organizationBalanceConfigType: null,
    organizationBaseId: null,
    organizationStatus: null,
    organizationType: null,
    planName: null,
    planType: null,
  }

  const userData = useStorage<IUserData>(userDataKey, initialUserData)
  const organizationData = useStorage<IOrganizationData>(
    organizationDataKey,
    initialOrganizationData,
  )

  const isUserAuthenticated = computed(() =>
    Boolean(accessToken.value && refreshToken.value && userData.value?.userId),
  )

  function logout() {
    accessToken.value = null
    refreshToken.value = null
    userData.value = emptyUserData
    organizationData.value = initialOrganizationData
    return navigateTo({ name: 'auth-login' })
  }

  function setAccessToken(value: string) {
    accessToken.value = value
  }

  function setRefreshToken(value: string) {
    refreshToken.value = value
  }

  function setUserData(value: NLogin.IResponseData) {
    userData.value = {
      userId: value.id,
      name: value.name,
      email: value.email,
    }
  }

  function updateUserEmail(email: string) {
    userData.value.email = email
  }

  function setUserSession(value: IUserData) {
    userData.value.createdDateTime = value.createdDateTime
    userData.value.name = value.name
    userData.value.email = value.email
    userData.value.language = value.language

    userData.value.timeZone = value.timeZone
    userData.value.dateFormat = value.dateFormat

    if (value.language) {
      const languageStore = useLanguageStore()
      languageStore.setUserLanguage(value.language)
    }
  }

  function setOrganizationSession(value: NGetSession.Organization) {
    organizationData.value = {
      name: value.name,
      organizationBalanceConfigType: value.organizationBalanceConfigType,
      organizationBaseId: value.organizationBaseId,
      organizationStatus: value.organizationStatus,
      organizationType: value.organizationType,
    }
  }

  function setOrganizationPlanSession(
    planName: string | null,
    planType: number | null,
  ) {
    organizationData.value.planName = planName
    organizationData.value.planType = planType
  }

  function isSentitUser() {
    return (
      organizationData.value.organizationType === OrganizationTypeEnum.SENDIT
    )
  }

  return {
    accessToken,
    refreshToken,
    userData,
    organizationData,
    isUserAuthenticated,
    setAccessToken,
    setRefreshToken,
    setUserData,
    updateUserEmail,
    setUserSession,
    setOrganizationSession,
    setOrganizationPlanSession,
    logout,
    isSentitUser,
  }
})
