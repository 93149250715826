import type { IPageOptionsRequest, IPageOptionsResponse } from '../../types'

export enum OrganizationStatusEnum {
  Inactive = 1,
  Active = 2,
  PendingInviteAcceptance = 3,
  Deleted = 4
}

export enum OrganizationTypeEnum {
  SENDIT = 1,
  CLIENT = 2
}

export enum OrganizationBalanceTypeEnum {
  CONSUMES_FROM_PARENT = 1,
  DISTRIBUTED_FROM_PARENT = 2,
  INDEPENDENT = 3
}

// export enum OrganizationBalanceTypeEnum {
//   NO_OWN_BALANCE = 1,
//   OWN_BALANCE_DEPENDENT = 2,
//   OWN_BALANCE_INDEPENDENT = 3
// }

export namespace NGetOrganizations {
  export interface IParams {}

  export interface IParentBillingInfo {
    id: number
    name: string
    taxIdentificationNumber: string
    address: string
    postalCode: string
    city: string
    country: string
    phoneCountryIndicator: number
    phoneNumber: string
    email: string
    countryId: string
    detailCountry: string
    isFinalConsumer: boolean
  }

  export interface IOrganizationContactDetail {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: string
    organizationId: number
    contactDetailsType: number
    name: string
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
  }

  export interface IBillingInfo {
    id: number
    name: string
    taxIdentificationNumber: string
    address: string
    postalCode: string
    city: string
    country: string
    phoneCountryIndicator: number
    phoneNumber: string
    email: string
    countryId: string
    detailCountry: string
    isFinalConsumer: boolean
  }

  export interface IOrganization {
    parentBillingInfo: IParentBillingInfo
    organizationId: string
    companyName: string
    organizationType: number
    businessArea: number
    organizationStatus: number
    businessProfile: number
    parentOrganizationId: number
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
    employees: number
    nodePath: string
    inactiveDateTime: any
    isNew: boolean
    deletedDateTime: any
    createdByUserId: string
    invoiceType: number
    isUsingDefaultInvoiceInfo: boolean
    organizationBalanceConfigType: number
    isUsingParentBillingInfo: boolean
    billingInfo: IBillingInfo
    organizationContactDetails: IOrganizationContactDetail[]
    id: number
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
  }

  export interface IItem {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: number
    organizationId: string
    companyName: string
    organizationType: number
    businessArea: number
    organizationStatus: number
    businessProfile: number
    parentOrganizationId: number
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
    employees: number
    nodePath: string
    inactiveDateTime: string
    isNew: boolean
    deletedDateTime: string
    createdByUserId: string
    invoiceType: number
    isUsingDefaultInvoiceInfo: boolean
    organizationBalanceConfigType: number
    isUsingParentBillingInfo: boolean
    billingInfo: IBillingInfo
    organizationContactDetails: IOrganizationContactDetail[]
  }

  export interface IOrganizationDetails {
    organizationId?: string
    companyName?: string
    phoneCountryIndicator: string
    phoneNumber: string
    email: string
    businessArea: string
    businessProfile: number
    numberEmployes: string
  }

  export interface IResponseData {
    items: IItem[]
    pageOptionsResponse: IPageOptionsResponse
  }
}

export namespace NGetBusinessAreas {
  export interface IParams {}

  export interface IResponseData {
    value: string
    label: string
    group: string
  }
}

export namespace NGetUsers {
  export interface IParams extends IPageOptionsRequest {}

  export interface IItem {
    userId: string
    inviteId?: string
    roleId: string
    name: string
    email: string
    roleName: string
    organizationUserStatus: number
    organizationUserId: string
    createdDateTime: string
  }

  export interface IResponseData {
    items: IItem[]
    pageOptionsResponse: IPageOptionsResponse
  }
}

export namespace NDesactivateUser {
  export interface IParams {}

  export interface IUser {
    lockoutEnd: string
    twoFactorEnabled: boolean
    phoneNumberConfirmed: boolean
    phoneNumber: string
    concurrencyStamp: string
    securityStamp: string
    password: string
    passwordHash: string
    emailConfirmed: boolean
    normalizedEmail: string
    email: string
    normalizedUserName: string
    userName: string
    id: string
    lockoutEnabled: boolean
    accessFailedCount: number
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    name: string
    emailValidationCode: number
    emailValidationCodeDate: string
    emailConfirmationDate: string
    phoneNumberValidationCode: number
    phoneNumberValidationCodeDate: string
    phoneNumberConfirmationDate: string
    phoneCountryIndicator: number
    country: string
    timeZone: string
    dateTimeFormat: string
    language: number
    userStatus: number
    inactiveDateTime: string
    msisdn: string
  }

  export interface IBillingInfo {
    id: number
    name: string
    taxIdentificationNumber: string
    address: string
    postalCode: string
    city: string
    country: string
    phoneCountryIndicator: number
    phoneNumber: string
    email: string
    countryId: string
    detailCountry: string
    isFinalConsumer: boolean
  }

  export interface IOrganizationContactDetails {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: string
    organizationId: number
    contactDetailsType: number
    name: string
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
  }

  export interface IOrganization {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: number
    organizationId: string
    companyName: string
    organizationType: number
    businessArea: number
    organizationStatus: number
    businessProfile: number
    parentOrganizationId: number
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
    employees: number
    nodePath: string
    inactiveDateTime: string
    isNew: boolean
    deletedDateTime: string
    createdByUserId: string
    invoiceType: number
    isUsingDefaultInvoiceInfo: boolean
    organizationBalanceConfigType: number
    isUsingParentBillingInfo: boolean
    billingInfo: IBillingInfo
    organizationContactDetails: IOrganizationContactDetails[]
  }

  export interface IResponseData {
    organizationUserId: string
    organizationId: number
    userId: string
    organizationUserStatus: number
    inactiveDateTime: string
    deleteDateTime: string
    isNew: boolean
    organization: IOrganization
    user: IUser
    id: number
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
  }
}

export namespace NActivateUser {
  export interface IParams {}

  export interface IUser {
    lockoutEnd: string
    twoFactorEnabled: boolean
    phoneNumberConfirmed: boolean
    phoneNumber: string
    concurrencyStamp: string
    securityStamp: string
    password: string
    passwordHash: string
    emailConfirmed: boolean
    normalizedEmail: string
    email: string
    normalizedUserName: string
    userName: string
    id: string
    lockoutEnabled: boolean
    accessFailedCount: number
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    name: string
    emailValidationCode: number
    emailValidationCodeDate: string
    emailConfirmationDate: string
    phoneNumberValidationCode: number
    phoneNumberValidationCodeDate: string
    phoneNumberConfirmationDate: string
    phoneCountryIndicator: number
    country: string
    timeZone: string
    dateTimeFormat: string
    language: number
    userStatus: number
    inactiveDateTime: string
    msisdn: string
  }

  export interface IBillingInfo {
    id: number
    name: string
    taxIdentificationNumber: string
    address: string
    postalCode: string
    city: string
    country: string
    phoneCountryIndicator: number
    phoneNumber: string
    email: string
    countryId: string
    detailCountry: string
    isFinalConsumer: boolean
  }

  export interface IOrganizationContactDetails {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: string
    organizationId: number
    contactDetailsType: number
    name: string
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
  }

  export interface IOrganization {
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
    id: number
    organizationId: string
    companyName: string
    organizationType: number
    businessArea: number
    organizationStatus: number
    businessProfile: number
    parentOrganizationId: number
    email: string
    phoneCountryIndicator: number
    phoneNumber: string
    employees: number
    nodePath: string
    inactiveDateTime: string
    isNew: boolean
    deletedDateTime: string
    createdByUserId: string
    invoiceType: number
    isUsingDefaultInvoiceInfo: boolean
    organizationBalanceConfigType: number
    isUsingParentBillingInfo: boolean
    billingInfo: IBillingInfo
    organizationContactDetails: IOrganizationContactDetails[]
  }

  export interface IResponseData {
    organizationUserId: string
    organizationId: number
    userId: string
    organizationUserStatus: number
    inactiveDateTime: string
    deleteDateTime: string
    isNew: boolean
    organization: IOrganization
    user: IUser
    id: number
    createdDateTime: string
    updatedDateTime: string
    createdBy: string
    lastUpdatedBy: string
  }
}

export namespace NDeleteUser {
  export interface IParams {}
  export type TResponseData = boolean
}

export namespace NChangeRole {
  export interface IParams {}
  export interface IBody {
    roleId: string
  }
  export type TResponseData = boolean
}

export namespace NGetUser {
  export interface IParams {}
  export interface IStatus {
    id: number
    description: string
  }
  export interface IResponseData {
    organizationUserId: string
    userName: string
    name: string
    status: IStatus
    roleId: string
    email: string
  }
}

export namespace NGetOrganizationUsers {
  export interface IStatus {
    id: number
    description: string
  }
  
  export interface IOrganizationUser {
    organizationUserId: string
    userName: string
    name: string
    status: IStatus
    roleId: any
    email: string
  }
  
  export interface IResponse {
    organizationUsers: IOrganizationUser[]
  }
}

export namespace NGetSuborganizations {
  export interface ISuborganization extends NGetOrganizations.IOrganization {}

  export interface IRequest extends IPageOptionsRequest {
    companyName?: string
    status?: string
  }

  export interface IResponse {
    items: ISuborganization[]
    pageOptionsResponse: IPageOptionsResponse
  }
}

