import { productFruits } from 'product-fruits'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const authStore = useAuthStore()
  const languageStore = useLanguageStore()
  const route = useRoute()

  const setupProductFruits = () => {
    // Delay para evitar propriedades undefined
    setTimeout(() => {
      const userData = authStore.userData
      const organizationData = authStore.organizationData
      const splittedName = userData.name?.split(' ')
      const language = languageStore.userLanguage.split('-')[0]

      if (!userData.userId) return

      productFruits.init(
        runtimeConfig.public.NUXT_PRODUCT_FRUITS_ID,
        language,
        {
          username: userData.userId,
          firstname: splittedName?.[0],
          lastname: splittedName?.[1],
          email: userData.email || undefined,
          signUpAt: userData.createdDateTime || undefined,
          props: {
            planName: organizationData.planName || '',
            organizationId: String(route.params.organizationId),
          },
        },
      )
    }, 500)
  }

  const restartProductFruits = () => {
    window.productFruits?.services?.destroy()
    setupProductFruits()
  }

  nuxtApp.hook('app:mounted', () => {
    setupProductFruits()
  })

  watch(
    () => ({
      organizationData: authStore.organizationData,
      userData: authStore.userData,
    }),
    () => {
      restartProductFruits()
    },
    { deep: true },
  )

  return {
    provide: {
      productFruits: {
        setupProductFruits,
        restartProductFruits,
      },
    },
  }
})
