import {
  loadStripe,
  type StripeElementLocale,
  type StripeElementsOptionsClientSecret,
} from '@stripe/stripe-js'

export default defineNuxtPlugin(async () => {
  const runtimeConfig = useRuntimeConfig()
  const stripeInstance = await loadStripe(runtimeConfig.public.NUXT_STRIPE_PK)

  // * Função para converter SUPPORTED_LANGUAGE_OPTIONS para StripeElementLocale
  const convertToStripeLocale = (
    language: SUPPORTED_LANGUAGE_OPTIONS,
  ): StripeElementLocale => {
    const localeMap: {
      [key in SUPPORTED_LANGUAGE_OPTIONS]: StripeElementLocale
    } = {
      [SUPPORTED_LANGUAGE_OPTIONS.English]: 'en',
      [SUPPORTED_LANGUAGE_OPTIONS.Portuguese]: 'pt',
    }

    return localeMap[language]
  }

  const getStripeElements = (
    setupSecret: string,
    userLanguage: SUPPORTED_LANGUAGE_OPTIONS,
    options?: StripeElementsOptionsClientSecret,
  ) => {
    const rootStyles = getComputedStyle(document.documentElement)
    const primaryColor = rootStyles
      .getPropertyValue('--cui-border-color')
      .trim()
    const purple300Color = rootStyles
      .getPropertyValue('--cui-purple-300')
      .trim()
    const invalidColor = rootStyles
      .getPropertyValue('--cui-form-invalid-color')
      .trim()

    return stripeInstance?.elements({
      clientSecret: setupSecret,
      locale: convertToStripeLocale(userLanguage),
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: primaryColor,
          colorDanger: invalidColor,
        },
        rules: {
          '.Input': {
            borderColor: 'var(--colorPrimary)',
          },
          '.Input:focus': {
            borderColor: purple300Color,
          },
        },
      },
      ...options,
    })
  }

  return {
    provide: {
      stripe: {
        stripeInstance,
        convertToStripeLocale,
        getStripeElements,
      },
    },
  }
})
